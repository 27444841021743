import request from '@/utils/request'

export function getUserCodeList(pageNum,pageSize) {
    return request({
        url: '/data/getUserCodeList?pageNum=' + pageNum + '&pageSize=' + pageSize, 
        method: 'get'
    })
}

export function editUserCodeList(id,data) {
    return request({
        url: '/data/editUserCodeList?id=' + id, 
        method: 'post',
        data
    })
}

export function delUserCodeList(id,data) {
    return request({
        url: '/data/delUserCodeList?id=' + id, 
        method: 'get',
        data
    })
}


export function getList(pageNum,pageSize) {
    return request({
        url: '/data/dataList?pageNum=' + pageNum + '&pageSize=' + pageSize, 
        method: 'get'
    })
}

export function delData(id) {
    return request({
        url: '/data/deleteData?id=' + id, 
        method: 'get',
    })
}


export function homes() {
    return request({
        url: '/data/datas/', 
        method: 'get',
    })
}
